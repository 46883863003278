<template>
  <div class="contact">
    <h2>服务与咨询</h2>
    <div class="h2_line"></div>
    <div class="contact_pagh">
      <h3>公司简介</h3>
      <div class="contact_pagh_line"></div>
      <p class="_desc" v-html="site_about"></p>
    </div>
    <div class="contact_pagh" style="margin-top: 35px;">
      <h3>商务合作&销售咨询</h3>
      <div class="contact_pagh_line"></div>
      <div class="contacts">
        <div class="pt_1" style="width: 400px;">
          <div class="_tit">商务合作</div>
          <p>公司：河北雄安太芯电子科技有限公司</p>
          <p>电话：0311-87091231</p>
          <p>邮箱：taixin@txtech.com</p>
        </div>
        <div class="pt_1" style="width: 400px;">
          <div class="_tit">销售咨询</div>
          <p>{{site_concat[0]}}</p>
          <p>{{site_concat[1]}}</p>
          <p>{{site_concat[2]}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Api } from "@conf";
export default {
  name: "Home",
  data() {
    return {
      logo: require("@assets/logo.png"),
      phone: require("@assets/phone.png"),
      site_about: '',
      site_concat: ''
    }
  },
  methods: {
    getSystemData() {
      Api.getSystemData().then(res => {
        const { data = [], } = res
        data.forEach(item => {
          if (item.key === 'site_about') {
            this.site_about = item.key_value
          }
          if (item.key === 'site_concat') {
            this.site_concat = item.key_value.split("\n")
          }
        })
      })
    }
  },
  mounted() {
    this.getSystemData()
  }
}
</script>

<style lang="less" scoped>
.contact{
  .contact_pagh{
    .pt_1{
      padding-top: 20px;
      text-align: left;
      ._tit{
        color: #444444;
        font-size: 13px;
        font-weight: bold;
      }
      font-size: 14px;
      font-weight: 500;
      color: #777777;
      line-height: 24px;
    }
    .contacts{
      width: 100%;
      height:auto;
      display: flex;
    }
    width: 100%;
    height: auto;
    padding-top: 50px;
    ._desc{
      padding-top: 23px;
      text-align: left;
      width: 900px;
      height: 66px;
      font-size: 14px;
      font-weight: 500;
      color: #777777;
      line-height: 22px;
    }
    h3{
      text-align: left;
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 1;
    }
    .contact_pagh_line{
      width: 900px;
      height: 1px;
      background: #BBBBBB;
      margin-top: 14px;
    }
  }
  width: 900px;
  height: auto;
  margin: 0 auto;
  padding-top: 70px;
  h2{
    width: 150px;
    height: 30px;
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    line-height: 1;
    text-align: left;
    margin: 0;
  }
  .h2_line{
    width: 44px;
    height: 3px;
    background: #176BB2;
    //border: 2px solid #176BB2;
    margin-top: 12px;
  }


}
</style>
